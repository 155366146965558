import React, { useMemo } from 'react'
import { graphql, Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import { SendEnquiryBlock } from '/src/components/common'

export const Head = ({ data }) => {
  const [resource] = data.allNodeResources.edges
  return <title>{resource.node.title} | Orrcon Steel</title>
}

const ResourceContent = ({ body }) => {
  return body.map(({ processed }) => {
    return (
      <p className="body mb-2" dangerouslySetInnerHTML={{
        __html: processed
      }}></p>
    )
  })
}

/*const ResourceImage = ({ title, relationships }) => {
  const { field_resource_cover } = relationships
  if (!field_resource_cover) return <></>

  return (
    <ImageViewer images={[
      <GatsbyImage alt={title} image={field_resource_cover.gatsbyImage} />,
      <GatsbyImage alt={title} image={field_resource_cover.gatsbyImage} />,
      <GatsbyImage alt={title} image={field_resource_cover.gatsbyImage} />
    ]} />
  )
}*/

const ResourceDownload = ({ relationships }) => {
  const { field_resource_file } = relationships
  if (!field_resource_file || !field_resource_file.length) return <></>

  return <a className="download-button button primary" target="_blank" href={field_resource_file[0].uri.url}>
    Download
    <FontAwesomeIcon icon={faFileArrowDown} />
  </a>
}

const getResourceType = ({ node }) => {
  const { field_resource_category } = node.relationships
  if (!field_resource_category || !field_resource_category.length) return 'resources'

  return field_resource_category[0].name
}

const getResourcePath = ({ node }) => {
  const path = node.path.alias.split('/')
  return `/${path[1]}/${path[2]}`
}

export default ({ data }) => {
  const [resource] = data.allNodeResources.edges

  if (!resource) {
    return 'no resource found.'
  }

  return (
    <Layout>
      <Row className="mt-5 mb-5">
        <Col xs={12}>
          <div className="mb-5">
            <Link className="back-to-link" to={getResourcePath(resource)}>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to {getResourceType(resource)}
            </Link>
          </div>

          <Row>
            <Col sm={8} className="d-flex align-items-center">
              <div className="title-container mb-4">
                <h1 className="title">
                  {resource.node.title}
                </h1>
              </div>
            </Col>
            <Col sm={4} className="d-flex align-items-center justify-content-end">
              <ResourceDownload {...resource.node} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 mb-5">
        <Col md={8}>
          <ResourceContent {...resource.node} />
        </Col>
        <Col md={4}>
          
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={12}>
          <SendEnquiryBlock />
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    allNodeResources(filter: {id: {eq: $id}}) {
      edges {
        node {
          title
          body {
            processed
          }
          path {
            alias
          }
          relationships {
            node__product {
              title
              path {
                alias
              }
            }
            field_resource_file {
              uri {
                url
              }
            }
            field_resource_category {
              name
            }
            field_resource_cover {
              gatsbyImage(
                fit: COVER
                formats: AUTO
                layout: FULL_WIDTH
                quality: 100
                placeholder: BLURRED
                width: 315
                height: 225
              )
            }
          }
        }
      }
    }
  }
`